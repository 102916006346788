<template>
    <div>Logout</div>
</template>

<script>
    export default {
        name: 'TheLogout',
        beforeRouteEnter( to, from, next ) {
            next( false );
        },
    };
</script>
